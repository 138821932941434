import React, { ReactElement, ComponentType } from "react";
import { Suspense } from "react";
import Loader from "../Loader";

interface LazyLoaderProps {
  component: ComponentType;
}

const LazyLoader: React.FC<LazyLoaderProps> = ({
  component: Component,
}: LazyLoaderProps): ReactElement => {
  return (
    <Suspense fallback={<Loader />}>
      <Component />
    </Suspense>
  );
};

export default LazyLoader;
