import { memo, lazy, Suspense, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import LazyLoader from "./components/common/LazyLoader/Index";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { Helmet } from "react-helmet";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const SignIn = lazy(() => import("./pages/SignIn"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));
const ActivationPage = lazy(() => import("./pages/ActivationPage"));
const ForgotPasswordPage = lazy(() => import("./pages/ForgotPasswordPage"));
const ServerErrorPage = lazy(() => import("./pages/ServerErrorPage"));

export default function App() {
  const MemoizedVerifyUser = memo(ActivationPage);
  const location = useLocation();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-LW8WJHCTZY', {
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <ErrorBoundary>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-LW8WJHCTZY"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-LW8WJHCTZY');
          `}
        </script>
      </Helmet>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LazyLoader component={LandingPage} />} />
          <Route path="/signin" element={<LazyLoader component={SignIn} />} />
          <Route path="/forgot-password" element={<LazyLoader component={ForgotPasswordPage} />} />
          <Route path="/verify/:token" element={<LazyLoader component={MemoizedVerifyUser} />} />
          <Route path="/page-not-found" element={<LazyLoader component={NotFoundPage} />} />
          <Route path="/server-error" element={<LazyLoader component={ServerErrorPage} />} />
          <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
